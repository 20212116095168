<template>
  <v-dialog v-model="dialog" max-width="700" retain-focus persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-container fluid>
        <v-card-title><v-icon class="mb-2 mr-2">mdi-cog</v-icon> Configurar nova(s) task(s):</v-card-title>
        <v-card flat class="ma-4">
          <v-autocomplete v-model="selectedTasks" label="Tasks" chips item-text="task" :items="selectableTasks" multiple />
          <v-text-field v-model="feature" label="Feature" />
        </v-card>
        <v-row no-gutters class="mx-4 my-2">
          <v-btn class="mr-2" color="error" @click="close">Cancelar</v-btn>
          <v-btn :disabled="!selectedTasks.length" color="primary" @click="insertNewTasks">Inserir</v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_ADD_NEW_TASK } from '@/modules/feature_branch/graphql'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    tasks: {
      type: Array,
      default: () => []
    },
    db_name: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    defaultList: [
      'import_global_files',
      'mongo_enrichments',
      'mongo_importer',
      'mongo_exporter',
      'pdv_migration_backup_client',
      'pdv_migration_migrate_client_pre',
      'pdv_migration_migrate_client_post',
      'status_reporter',
      'summarize_mongo_data',
      'expire_vouchers',
      'import_app_information',
      'data-validation-pos-process-zip',
      'pdv_processor',
      'aggregate_whatsapp_information',
      'set_purchased_units_on_user',
      'update_points',
      'update_cashback',
      'summarize_transactional_tribanco_data',
      'products_relevance',
      'synchronize_sales',
      'update_segments',
      'update_raffles',
      'apply_summaries',
      'generate_frequency_summaries',
      'generate_smart_summaries',
      'materialize_summaries',
      'swap_summaries',
      'jarvis_schedule',
      'process_input_s3_key',
      'apply_migrations_monet',
      'pre_monet',
      'monet_etl',
      'voucher_sale_matcher',
      'sale_item_consumed_sale_matcher',
      'order_sale_matcher',
      'data-validation-pos-pre-monet',
      'geo_consumer',
      'integrate',
      'process_zip',
      'start_queue_task',
      'update_offers_schedules',
      'score_products',
      'bucket_cleaner',
      'monet_exporter',
      'check_products_migrated',
      'coupon_sale_confirmation_matcher',
      'potential_gain',
      'precalculate_rfv',
      'update_accounts_metrics',
      'benefit_sale_matcher',
      'cleanup_segments',
      'end_queue_task',
      'update_segment_memberships'
    ],
    selectedTasks: [],
    feature: ''
  }),
  computed: {
    selectableTasks() {
      return this.defaultList.filter(val => !this.tasks.includes(val))
    },
    all() {
      return this.selectedTasks.length === this.selectableTasks.length
    },
    partial() {
      return this.selectedTasks.length > 0 && !this.all
    },
    icon() {
      if (this.all) return 'mdi-close-box'
      if (this.partial) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    close() {
      this.feature = ''
      return this.$emit('close')
    },
    toggle() {
      this.$nextTick(() => {
        if (this.all) {
          this.selectedTasks = []
        } else {
          this.selectedTasks = this.selectableTasks.slice()
        }
      })
    },
    async insertNewTasks() {
      const variables = {
        db_name: this.db_name,
        tasks: this.selectedTasks,
        feature: this.feature
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ADD_NEW_TASK,
          variables
        })
        this.selectedTasks = []
        this.close()
        this.$snackbar({ message: 'Nova(s) task(s) inseridas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        this.$snackbar({ message: 'Falha ao adicionar novas tasks', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
